<script lang="ts" setup>
import type { HomeBanners, QuestInfoData } from "@/types";
const { t } = useT();
const { open } = useTaoModals();

const props = defineProps<{ banner: HomeBanners[number]; questData: QuestInfoData }>();

const openChallengeSeason = () => {
	dispatchGAEvent({
		event: "click_button",
		button_name: props.questData?.questInfo?.isAvailable ? "winter_challenge_popup" : "get_ticket",
		location: "winter_challenge_banner"
	});

	if (props.questData?.questInfo?.isAvailable) {
		open("LazyOModalChallengeSeason");
		return;
	}

	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};
</script>

<template>
	<MBanner
		:bg-img="banner.backgroundImage"
		:bg-img2x="banner.backgroundImage2x"
		:bg-color="banner.background"
		:image="banner.image"
		:image-mobile="banner.imageMobile"
		:second-image="banner.secondImage"
		:second-image-mobile="banner.secondImageMobile"
		:type="banner.type"
		@click="openChallengeSeason"
	>
		<template #default>
			<AText class="banner-title" variant="tuzla" :modifiers="['uppercase']">
				<p v-html="banner.title" />
			</AText>
		</template>
		<template #description>
			<AText class="banner-title" variant="tanzay" :modifiers="['bold', 'uppercase']">
				<p v-html="banner.smallTitle" />
			</AText>
		</template>

		<template #actions>
			<AButton v-if="!questData?.questInfo?.isAvailable" variant="primary" class="app-banner__btn">
				<AText variant="tempe" :modifiers="['bold', 'uppercase']">{{ t("Get ticket") }}</AText>
				<NuxtIcon name="20/ticket" class="icon-ticket" filled />
			</AButton>
			<AButton v-else variant="primary" class="app-banner__btn">
				<AText variant="tempe" :modifiers="['bold', 'uppercase']">{{ t("Explore challenges") }}</AText>
			</AButton>
		</template>
	</MBanner>
</template>

<style lang="scss" scoped>
.banner-title {
	@include media-breakpoint-down(md) {
		max-width: 300px;
	}
}
.icon-ticket {
	font-size: 24px;
	margin-left: gutter(1);
}
</style>
